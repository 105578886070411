<template>
    <o-data-lookup :data-object="dsLookupDataObject" :disableRecent="true" :whereClause="getWhereClause" reloadOnWhereClauseChange>

        <template #toolbarActions>
            <div class="form-check">
                <input class="form-check-input" id="otLookup_RestrictToContext" v-model="restrictedToContext" type="checkbox" checked @click="toggleContext" >
                <label class="form-check-label" for="otLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
            <slot name="toolbarActions"></slot>
        </template>

        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.functioncode" :ref="scope.target" style="cursor: pointer;">
                <slot name="functioncode"></slot>
            </span>
        </template>
        
        <o-column field="Name" width="250"></o-column>
        <o-column field="Description" width="250"></o-column>
        <o-column field="CodeSet" width="200"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts'
    import context from "o365.modules.Context.ts";

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const restrictedToContext = ref(true);
    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_FunctionCodes',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "CodeSet", type: "string", sortOrder: 1, sortDirection: "asc" },
              {name: "Name", type: "string", sortOrder: 2, sortDirection: "asc" },             
              {name: "Description", type: "string" },      
              {name: "OrgUnit", type: "string" }]
    });


    const getWhereClause = computed( () => {
        var vFilter = [];
        if (restrictedToContext.value) {    
            if (props.IdPath) {
                vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
            }
            else {
                vFilter.push("'" + context.idPath + "' LIKE IdPath + '%'");
            }
        }  

        return vFilter.join(" AND ");
    });
      
    function toggleContext(e,o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
 
   
</script>